@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Shizuru&display=swap');

.body {
    font-family: 'Roboto';
    
}

:root {
    --lantik-red: #d30e12
           
}

* {
    margin: 0;
    padding:0;

    

}

html {
    box-sizing: border-box;
    
    /* margin: 0px;
    padding: 0px; */
}

.container {
    display: grid;
    grid-template-columns:240px 2fr 1fr 1fr;
    grid-row: auto;
    grid-gap: 0;
    grid-template-areas:
    "main-sidebar main-top main-top main-top"
    "main-sidebar main main main"
    "main-sidebar main main main";
    grid-template-rows: auto 1fr 1fr;
    height: 100vh;
   
  
    font-family: 'Roboto', sans-serif;

}

.main-logo {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    text-align: left;
    grid-area: header-logo;
    padding-left: 10%;
    
}

.main-sidebar {
    background-color: white;
    grid-area: main-sidebar;
    
    
    
 
}


.main-top {
    background-color: black;
    grid-area: main-top;
   
  
}


.t-bar {
    color: var(--lantik-red);
    align-items: center;
    justify-content: flex-end;
    padding: 20px 50px 5px 5px;
    display: flex;
    flex-direction: row;
}

.hamburger_icon{
    margin-left: 35px;
    margin-right: auto;
}

.sidebar {
    color: var(--lantik-red);
    display: grid;
    grid-template-areas:
    "s-logo"
    "s-nav";
   
    grid-template-rows: 10px 1fr 130px;
    text-align: center;
    align-items: center;
    padding: 2.5rem;
    grid-area: sidebar;

    
    padding-left: 8%;
    padding-right: 10%;
    height: 70vh;
}

.sidebar.logo {
    background-color: aqua;
    grid-area: s-logo;
  
    
}

.img-logo {
    height: 100%;
    min-height: 80px;
    min-width: 100px;
    padding: 5px;
    background-image: url("images/logo@1x.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150px;
}

 .text-logo {
    padding-top: 20px;
} 

.sidebar.nav {
    grid-area: s-nav;
 
    
}

.sidebar.footer {
    grid-area: s-footer;
   
}

.main-sidebar a {
    list-style-type: none;
    color: black;
    font-weight: 500;
    font-size: 1.7rem;
    display: block;
    padding: 1rem;
    text-decoration: none;
}

a:hover {
    font-size: 1.8rem;
    transition: .1s;
}

.main-content {
    padding: 2.5rem;
    grid-area: main;
    background-color: RGB(249, 249, 249) ;


}


.anonymous-welcome {

    height: 100vh;
    display: grid;
}


.prompt {
    text-align: center;
    align-self: center;
    justify-self: center;
}


textarea#styled {
	width: 600px;
	height: 120px;
	border: 3px solid #cccccc;
	padding: 5px;
	font-family: Tahoma, sans-serif;
	background-position: bottom right;
	background-repeat: no-repeat;
}

select{
	border: 1px solid rgba(34,36,38,.15);
	padding: 5px;
    font-family: Tahoma, sans-serif;
    color: rgba(0,0,0,.5);
	background-position: bottom right;
	background-repeat: no-repeat;
    border-radius: 4px;
    line-height: 1.21428571em;
    height: 40px;
    width: 150px;
}
.h1 {
    text-align: center;
}

.paddingTableNews {
    padding-top: 3rem;
}

.version {

 margin-bottom: -600px;
}
.thumbnailImageMini {
    padding: 5px;
}
.thumbnailImage {
    padding: 5px;
    width: 50%;
    height: 50%;
}
.largeImage{
    width: 100%;
    height: 100%;
}
.formImage{
    padding-left: 25px;
    width: 50%;
    height: 50%;
    
}

.optionsComponent{
    position: relative;
    border-radius: 6px;
    width: 160px;
    height: 85px;
    box-shadow: 0px 5px 10px 0px #0000002c;
    top: 6% !important;
    left: 3% !important;
}
.optionsList{
    list-style-type: none;
}
.optionsList li{
    padding-left: 15px;
    padding-top: 15px;
}
.tableData{
    width: 100%;
    height: 100%;
}
.popup-content {
    position: absolute;
    top: 10% !important;
    left: 10% !important;
    margin: auto;
    background: rgb(255, 255, 255) !important;
    width: 80% !important;
    height: 80% !important;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
}
.uploadComponent {
    display: block !important;
    width: 80% !important;
    height: 20% !important;
}
.hide-uploadComponent {
    display: none !important;
}
.buttonComponent {
    display: in-line !important;
}
.hide-buttonComponent {
    display: none !important;
}
.hide-ErrorComponent {
    display: none !important;
}
.hide-NoticiaComponent {
    display: none !important;
}
.hide-AppComponent {
    display: none !important;
}
.hide-OtroComponent {
    display: none !important;
}
.hide-VideoComponent {
    display: none !important;
}
.hide-FunComponent {
    display: none !important;
}

.hide-PopUpComponent {
    display: none !important;
}


.noImage{
    width: 160px;
    height: 120px;
    border: 0px;
    border-radius: 6px;
    background-color: RGB(33, 33, 33);
    color: white;
}
.imageSpan{
    position: relative;
    vertical-align:top;
    left: 25% !important;
    width: 70%;
    height: 70%;
}

.optionsList{
    list-style-type: none;
}
.optionsList li{
    padding-left: 15px;
    padding-top: 15px;
}


.popUpComponent {
    overflow: auto;
    width:100%;
	height:128%;
    position:absolute;
	top:1%;
	left:1%;
	z-index:1001;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px #0000002c;
    padding: 15px;
    border: 1px solid #cccccc;
    background-color: white;
    display: block;
}
.fullscreen-container {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(90, 90, 90, 0.5);
    z-index: 100;
  }
.ErrorComponent {
    text-align: center;
    width:500px;
	height:130px;
    position:absolute;
	top:200px;
	left:300px;
	z-index:100;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px #0000002c;
    padding: 15px;
    border: 1px solid #cccccc;
    background-color: white;
    display:  block;
}
.hide-MessageComponent {
    display: none !important;
}
.MessageComponent {
    font-size: 16px;
    text-align: center;
    width:450px;
	height:220px;
    position:absolute;
	top:200px;
	left:300px;
	z-index:100;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px #0000002c;
    border: 1px solid #cccccc;
    background-color: white;
    display:  block;
    padding: 20px;
}

.hide-ConfirmationMessageComponent {
    display: none !important;
}
.ConfirmationMessageComponent {
    font-size: 16px;
    text-align: center;
    width:450px;
	height:220px;
    position:absolute;
	top:200px;
	left:300px;
	z-index:100;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px #0000002c;
    border: 1px solid #cccccc;
    background-color: white;
    display:  block;
    padding: 20px;
}
.MessageComponent label{
    font-weight: Bold;
    font-size: 18px;
    padding-bottom: 5px;
}
.msgHead{
    position: absolute;
    top: -40px;
    left: 40%;
    background-color: RGB(242, 242, 242);
    height: 80px;
    width: 80px;
    border-radius: 40px;
    font-size: 35px;
}
.headIcon{
    position: absolute;
    top: 20px;
    left: 20px;
}
.msgBody{
    position: absolute;
    top: 30%;
    padding: 10px;
}
.msgBottom{
    position: absolute;
    bottom: 10px;
    left: 30%;
}
.esComponent {
    display: in-line !important;
}
.hide-EsComponent {
    display: none !important;

}

.hide-ImageEucomponent{
    display: none !important;
}
.hide-ImageEscomponent{
    display: none !important;
}

.euComponent {
    display: block !important;
}
.hide-EuComponent {
    display: none !important;
}
.hide-Component {
    display: none !important;
}
.show-Component {
    display: block !important;
}
.show-videoPlayer{
    display: block !important;
    width: 27% !important;
    height: 27% !important;
}
.iciELI {
    text-align: center;
    padding: 30px;
    border: 3px dashed #eeeeee;
    background-color: white;
    color:  white;
    cursor: pointer;
    margin-bottom: 20px;
    width: 80% !important;
    height: 20% !important;
  }
.search-icon{
    position: absolute;
    top: 20px;
    left: 20px;
    width: 32px;
    height: 32px;
    color: white;
    border: #d01919;
    background: #d01919;
    border-radius: 6px;
    border: 1px solid #ddd;
}
.pencil-icon{
    position: absolute;
    top: 20px;
    left: 30px;
    width: 32px;
    height: 32px;
    color: white;
    border: #d01919;
    background: #d01919;
    border-radius: 6px;
    border: 1px solid #ddd;
}
.button-icon{
    width: 32px;
    height: 32px;
 
    border: white;
    background: white;
}
.button-40{
    width: 40px;
    height: 40px;
}
.tableHeader{
    height: 50px;
    background:#F2F2F2;
    font-weight: Bold;
    font-size: 14px;
}
.trTablePopUp{
    font-weight: Medium;
    font-size: 14px;
}
.mainDiv{
    width: 97%;
    height: 80%;
    background-color: white;
    position: relative;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px #0000002c;
    padding: 5px;
}
.mainDiv2{
    width: 97%;
    height: 80%;
    background-color: white;
    /* position: relative;
    left: 0px;
    top: 0px; */
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px #0000002c;
    padding: 5px;
}
.noBorder{
    border: 0px !important;
    background-color: white !important;
    color: RGB(210, 10, 17) !important;
}


.redBorder{
    border: 1px solid RGB(210, 10, 17) !important;
    border-radius: 6px;
    background-color: white !important;
    color: RGB(210, 10, 17) !important;
}

.red-Select{
    border: 0px !important;
    background-color: white !important;
    color: RGB(210, 10, 17) !important;
    height: 35px;
    width: 150px;
}
.red-Select:focus{ outline: none;}

.red-box option{
    background-color: white !important;
    border-radius: 6px !important;
    border: 0px !important;
    box-shadow: 0px 5px 10px 0px #0000002c !important;
}

.red-box {
    display: inline;
    border: 0px;
    height: 35px;
    width: 150px;
    overflow: hidden;
    color: RGB(210, 10, 17) !important;
 }

.red-box::after{
	display:table-cell;
	padding-top:7px;
	text-align:center;
	width:30px;
	height:30px;
	background-color:#d9d9d9;
	position:absolute;
	top:0;
	right:0px;	
	pointer-events: none;
    border: 1px solid  rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
input[type="radio"]{
    display: none;
}
input[type="radio"] ::after{
    display: block;
}
input[type="radio"] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 25px;
    height: 25px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: white left top no-repeat;
    border: 1px solid RGB(210, 10, 17);
    cursor: pointer;
    color: white;
    border-radius: 6px;
    text-align: center;
}
input[type="radio"]:checked + span {
    background: RGB(210, 10, 17) -19px top no-repeat;
    border: 0px;
}
input[type="radio"] + span {
    margin-right: 4px;
}

input[type="radio"]{
    display: none;
}
input[type="radio"] ::after{
    display: block;
}
input[type="radio"] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 25px;
    height: 25px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: white left top no-repeat;
    border: 1px solid RGB(210, 10, 17);
    cursor: pointer;
    color: white;
    border-radius: 6px;
    text-align: center;
}
input[type="radio"]:checked + span {
    background: RGB(210, 10, 17) -19px top no-repeat;
    border: 0px;
}
input[type="radio"] + span {
    margin-right: 4px;
}


.div-inLine{
    display: inline;
}

.div-inLine-block {

    display: inline-block;
}

.title{
   font-size: 24px;
   font-weight: bold;
   color: RGB(76, 76, 76);
   padding: 15px;
}

.sub-title{
    font-size: 18px;
    font-weight: bold;
    color: RGB(76, 76, 76);
    padding: 15px;
    margin-top: 5px;
}

.search {
    float: right;
    margin-top: 30px;
}


.App {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 5em;
   
  }
  
  .editor {
    margin-right: 10em;
    width: 100em;
   
  }
  
  .ck-editor__editable_inline {
    min-height: 200px;
   width:97em;
  }

.version {

 margin-bottom: -600px;
}

.h1 {
    text-align: center;
}

.paddingTableNews {

    padding-top: 3rem;
}

.status-form {
    width: 380px;
}

.title-form {
    padding-right: 105px;
}

.photo {
    width: 161px;
    height: 119px;
    border-radius: 6px;
    background-color: black;
   float: right;
   margin-top: -100px;
   margin-right: 900px;
   margin-bottom: 10px;
   
}

.tags-input-container {
    border: 2px solid grey;
    padding: .5em;
    border-radius: 3px;
    width: min(80vw, 97rem);
    margin-top: 1em;

}

/*modal*/
.button {
    display: block;
    margin: 0 auto;
    background-color: red;
    color: white;
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 20px;
  }
  
  .modal-background {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(10, 10, 10, 0.86);
  }
  
  .modal-card {
    margin: 0 auto;
    display: block;
    margin-top: 100px;
    width: 800px;
    height: 600px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
  }


.languaje-view-news {
    float: right;
    height:100px;   
}

.msgHead{
    position: absolute;
    top: -40px;
    left: 40%;
    background-color: RGB(242, 242, 242);
    height: 80px;
    width: 80px;
    border-radius: 40px;
    font-size: 35px;
}

.msgBody{
    position: absolute;
    top: 30%;
    padding: 10px;
}

.msgBottom{
    position: absolute;
    bottom: 10px;
    left: 30%;
}

.button-icon{
    width: 32px;
    height: 32px;
    border: white;
    background: white;
}

.hide-MessageComponent {
    display: none !important;
}

.MessageComponent {
    font-size: 16px;
    text-align: center;
    width:450px;
	height:220px;
    position:absolute;
	top:200px;
	left:300px;
	z-index:100;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px #0000002c;
    border: 1px solid #cccccc;
    background-color: white;
    display:  block;
    padding: 20px;
}
.MessageComponent label{
    font-weight: Bold;
    font-size: 18px;
    padding-bottom: 5px;
}

.headIcon{
    position: absolute;
    top: 20px;
    left: 20px;   
}

.inline-flex {
    display: inline-flex;
    
}

.tags {
    background-color: #eeeeee;
    color: #d01919;
}

.image-new{
    text-align: center;
    width: 200px;
    height: 200px;
}
.image-container {
    margin-left: 100px;
}


.tags {

   background-color: red;
}

.lineaNew {
    border-top: 1px solid #F2F2F2;
    width: 100%;
}

.titleMenu {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}
.itemMenu{
    margin-left: 0px;
    margin-right: auto;
    color:#cccccc;
    text-align: left;
}

.linea {
    border-top: 0.5px solid #cccccc;
    width: auto;
}

.ck-editor__editable_inline {

    width: 100%;
}

.postion-section {
    margin-top: 35px ;
    margin-left: 15px;
}

.label-section {
    font-size: 25px;
    margin-left: 15px;
}

.position-subsection {
    font-size: 18px;
    margin-top: 5px;
    margin-left: 15px;
}


@media only screen and (max-width: 600px) {
    .container {
        grid-template-columns: 1fr;
        grid-row: auto;
        grid-gap: 0;
        grid-template-areas:
    "header-logo"
    "sidebar"
    "main"
    "footer";
        /* height: 100vh; */
    }

    .main-sidebar {
        display: none;
    }

    .main-content {
        overflow: visible;
    }
}

.dropdown-Lang-div{
    border-style: solid;
    border-color: white;
    border-radius: 4px;
    border-width: 1px; 
    margin-right: 30px;
    margin-left: 30px;
}

.dropdown-Lang {
    width: 65px;
    background-color: black;
    color: white;
    padding: 4px;
    text-align: center;
}

.titleMenu {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}
.itemMenu{
    margin-left: 0px;
    margin-right: auto;
    color:#cccccc;
    text-align: left;
}

.linea {
    border-top: 0.5px solid #cccccc;
    width: auto;
}
.posLeft{
    position: relative;
    top: 20%;
    width: 40%;
}
.posRight{
    width: 60%;
    position: absolute;
    top: 20%;
    left: 40%;   
}
.posLeft2{
    position: relative;
    top: 20%;
    width: 50%;
}
.posRight2{
    width: 50%;
    position: absolute;
    top: 10%;
    left: 50%;   
}
.playasTable{
    width: 100%;
}
.playasTable label{
    font-weight: bold;
}
.headerLine{
    display: inline-block !important;
    width: 100% !important;
    padding-left: 10px !important;
    margin-top: 30px;
}
.outSeasson{
    display: inline-block;
    width: 170px !important;
    height: 38px !important;
    background-color: RGB(255, 217, 57, 0.2);
    border-radius: 6px;
    border: 1px solid RGB(255, 217, 57);
    vertical-align: middle;
    text-align: center;
}
.inSeasson{
    display: inline-block;
    width: 170px !important;
    height: 37px !important;
    background-color: RGB(0, 160, 90, 0.2);
    border-radius: 6px;
    border: 1px solid RGB(0, 160, 90);
    vertical-align: middle;
    text-align: center;
}
.icon-alert{
    background-image: url("images/icon-alert-1.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 25px !important;
    height: 25px !important;
    filter: invert(85%) sepia(79%) saturate(752%) hue-rotate(324deg) brightness(103%) contrast(103%);
}
.icon-check-green{
    padding: 10px;
    background-image: url("images/icon-check.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 25px !important;
    height: 25px !important;
    filter: invert(40%) sepia(99%) saturate(587%) hue-rotate(111deg) brightness(93%) contrast(103%);
}
.icon-sun{
    background-image: url("images/icon-soleado.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-weather-NoData{
    background-image: url("images/icon-clima-sin-datos.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-variable{
    background-image: url("images/icon-clima-variable.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-rain{
    background-image: url("images/icon-lluvioso.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-cloud{
    background-image: url("images/icon-nublado.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-temperature{
    background-image: url("images/icon-temperatura.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-temperature-NoData{
    background-image: url("images/icon-temperatura-sin-datos.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-wind-noData{
    background-image: url("images/icon-viento-sin-datos.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-wind-low{
    background-image: url("images/icon-viento-flojo.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-wind-mod{
    background-image: url("images/icon-viento-moderado.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-wind-strong{
    background-image: url("images/icon-viento-fuerte.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-occupation-noData{
    background-image: url("images/icon-ocupacion-alta.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(90%) sepia(0%) saturate(10%) hue-rotate(165deg) brightness(93%) contrast(90%);
}
.icon-occupation-high{
    background-image: url("images/icon-ocupacion-alta.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-occupation-medium{
    background-image: url("images/icon-ocupacion-media.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-occupation-low{
    background-image: url("images/icon-ocupacion-baja.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-parking-noData{
    background-image: url("images/icon-parking-sin-datos.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-parking-low{
    background-image: url("images/icon-parking.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(30%) sepia(68%) saturate(3326%) hue-rotate(142deg) brightness(101%) contrast(103%);
}
.icon-parking-mid{
    background-image: url("images/icon-parking.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(29%) sepia(87%) saturate(7458%) hue-rotate(356deg) brightness(96%) contrast(124%);
}
.icon-parking-full{
    background-image: url("images/icon-parking.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(17%) sepia(91%) saturate(3940%) hue-rotate(349deg) brightness(79%) contrast(109%);
}
.icon-parking-not{
    background-image: url("images/icon-parking-not.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(36%) sepia(27%) saturate(7123%) hue-rotate(139deg) brightness(97%) contrast(102%);
}
.icon-asistido{
    background-image: url("images/icon-asistido.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-flag-green{
    background-image: url("images/icon-bandera.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(36%) sepia(27%) saturate(7123%) hue-rotate(139deg) brightness(97%) contrast(102%);
}
.icon-flag-red{
    background-image: url("images/icon-bandera.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(14%) sepia(97%) saturate(7496%) hue-rotate(7deg) brightness(106%) contrast(115%);
}
.icon-flag-yellow{
    background-image: url("images/icon-bandera.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(84%) sepia(36%) saturate(2291%) hue-rotate(329deg) brightness(102%) contrast(97%);
}
.icon-flag-grey{
    background-image: url("images/icon-bandera.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-temp-water{
    background-image: url("images/icon-temp-agua.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-waves-noData{
    background-image: url("images/icon-oleaje-sin-datos.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
}
.icon-waves-low{
    background-image: url("images/icon-oleaje-flojo.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-waves-mod{
    background-image: url("images/icon-oleaje-moderado.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-waves-strong{
    background-image: url("images/icon-oleaje-fuerte.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-hightide{
    background-image: url("images/icon-pleamar.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.icon-lowtide{
    background-image: url("images/icon-bajamar.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px !important;
    height: 24px !important;
    filter: invert(56%) sepia(48%) saturate(599%) hue-rotate(151deg) brightness(93%) contrast(93%);
}
.loading{
    background-image: url("images/loading.gif");
    background-position: center;
    background-repeat: no-repeat;
    width: 241px !important;
    height: 45px !important;
    position:absolute;
	top:200px;
	left:300px;
	z-index:100;
}
.date-picker-viewSwimmingPool input {
   border: 0;
   margin-top: 5px;
 }

 .checkPiscinas{
    height: 25px;
    width: 25px;
    margin-left: 10px;
 }

 .divContentEditableFalse{
    height: auto;
    padding:10px;
    border:solid;
    border-radius:5px;
    border-color:#BFC9CA; 
    border-width:1px;
    resize:none;
    background:#eaeaea;                
 }
 .icon-es-flag{
    background-image: url("images/es-flag.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 32px !important;
    height: 32px !important;
}
.icon-eu-flag{
    background-image: url("images/eu-flag.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 32px !important;
    height: 32px !important;
}
 .icon-android{
    background-image: url("images/android-flat.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 32px !important;
    height: 32px !important;
}
.icon-ios{
    background-image: url("images/apple-flat.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 32px !important;
    height: 32px !important;
}
.icon-phone{
    background-image: url("images/phone-solid.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 32px !important;
    height: 32px !important;
}
.icon-question{
    background-image: url("images/question-flat.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 32px !important;
    height: 32px !important;
    border-radius: 100px;
}

.table-button-red{
    background-color: #db2828 !important;
}
.table-button-red:disabled{
    background-color: #db2828 !important;
    color: white !important;
    opacity: 50%;
}

input[type="text"]:disabled {
    /* background-color: #eeeeee !important;
    color: #C6C6C6; */
    opacity: 0.7;
  }
  

.input-group-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-left: 15px;
}

.input-group, .checkbox-label {
    display: flex;
    align-items: center;
    margin-right: 25px;
    margin-bottom: 0.3rem;
    /* margin-bottom: 5px; */
}

.input-column-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-left: 15px;
    /* margin-bottom: 10px; */
}

.input-column{
    display: flex;
    flex-direction: column;
}

.label, .checkbox {
    margin-right: 8px;
    display: flex;
}

.country-flag{
    margin-right: 5;
    margin-bottom: 4;
    margin-left: 3;
}

.baseline{
    align-items: baseline;
}

.title-flx{
    display: flex;
    margin-top: 5px;
}

.input-length-counter {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    margin-right: 5px;
    opacity: 50%;
}

.input-length-counter-red{
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    margin-right: 5px;
    color: red;
}

.required-field{
    border-color: red !important;
}

.information-text {
    font-size: 12px;
    font-style: italic;
    margin-left: 8px;
  }

.rotating-icon {
    animation: rotate 4s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}  

.configure-home-link{
    font-size: 18px;
    color: #007bff;
}

.configure-home-link:hover{
    font-size: 18px;
    color: #0000ff;
}